import { useSearchParams } from "react-router-dom";
import TurnstileLivestock from "../components/TurnstileLivestock";

const VerifyHumanLivestock = () => {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1>Verify that you are human</h1>
      <TurnstileLivestock reportId={reportId} />
    </div>
  );
};

export default VerifyHumanLivestock;
