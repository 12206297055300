
import GlobalPricing from "../components/GlobalPricing";
import CommonLayout from "../components/layout/CommonLayout";

const CountryPrices = () => {
  return (
    <div>
      <CommonLayout isNavDark={true}>
        <div className="p-8">
          <GlobalPricing />
        </div>
      </CommonLayout>
    </div>
  );
};

export default CountryPrices;
