import { useState } from "react";
import { ChevronDown, ChevronUp, Search, Send, RefreshCw } from "lucide-react";

const faqs = [
  {
    question: "How do I reset my password?",
    answer:
      "To reset your password, click on the 'Forgot Password' link on the login page. You'll receive an email with instructions to create a new password.",
  },
  {
    question: "Can I upgrade my subscription?",
    answer:
      "Yes, you can upgrade your subscription at any time. Go to your account settings and select 'Upgrade Subscription' to see available options.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept all major credit cards (Visa, MasterCard, American Express) and PayPal. For enterprise customers, we also offer invoice-based payments.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "To cancel your subscription, go to your account settings and select 'Cancel Subscription'. Follow the prompts to complete the cancellation process.",
  },
  {
    question: "Is there a mobile app available?",
    answer:
      "Yes, we have mobile apps available for both iOS and Android devices. You can download them from the App Store or Google Play Store.",
  },
];

export default function Support() {
  const [openFaq, setOpenFaq] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [ticketForm, setTicketForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  //@ts-ignore
  const handleFaqToggle = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const handleTicketSubmit = (e: any) => {
    e.preventDefault();
    console.log("Ticket submitted:", ticketForm);
    // Here you would typically send the ticket data to your backend
    alert("Ticket submitted successfully!");
    setTicketForm({ name: "", email: "", subject: "", message: "" });
  };

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">
          Support & Help Center
        </h1>

        {/* Search Bar */}
        <div className="mb-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Search FAQs..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>
        </div>

        {/* FAQs */}
        <div className="bg-white rounded-lg shadow-md mb-8">
          <h2 className="text-xl font-semibold text-gray-800 p-6 border-b">
            Frequently Asked Questions
          </h2>
          <div className="divide-y">
            {filteredFaqs.map((faq, index) => (
              <div key={index} className="p-6">
                <button
                  className="flex justify-between items-center w-full text-left"
                  onClick={() => handleFaqToggle(index)}
                >
                  <span className="font-medium text-gray-900">
                    {faq.question}
                  </span>
                  {openFaq === index ? (
                    <ChevronUp className="text-gray-500" size={20} />
                  ) : (
                    <ChevronDown className="text-gray-500" size={20} />
                  )}
                </button>
                {openFaq === index && (
                  <p className="mt-2 text-gray-600">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Ticket Submission */}
        <div className="bg-white rounded-lg shadow-md mb-8">
          <h2 className="text-xl font-semibold text-gray-800 p-6 border-b">
            Submit a Ticket
          </h2>
          <form onSubmit={handleTicketSubmit} className="p-6">
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={ticketForm.name}
                onChange={(e) =>
                  setTicketForm({ ...ticketForm, name: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={ticketForm.email}
                onChange={(e) =>
                  setTicketForm({ ...ticketForm, email: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={ticketForm.subject}
                onChange={(e) =>
                  setTicketForm({ ...ticketForm, subject: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={ticketForm.message}
                onChange={(e) =>
                  setTicketForm({ ...ticketForm, message: e.target.value })
                }
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit Ticket
            </button>
          </form>
        </div>

        {/* Refunds and Support Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Refund Policy
            </h2>
            <p className="text-gray-600 mb-4">
              We offer a 30-day money-back guarantee on all our subscriptions.
              If you're not satisfied with our service, please contact our
              support team for a full refund.
            </p>
            <button className="flex items-center text-blue-500 hover:text-blue-600">
              <RefreshCw size={20} className="mr-2" />
              Request a Refund
            </button>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Contact Support
            </h2>
            <p className="text-gray-600 mb-4">
              Our support team is available 24/7 to assist you with any
              questions or issues you may have.
            </p>
            <div className="flex items-center mb-2">
              <Send size={20} className="text-gray-500 mr-2" />
              <span>support@example.com</span>
            </div>
            <div className="flex items-center">
              <Send size={20} className="text-gray-500 mr-2" />
              <span>+1 (800) 123-4567</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
