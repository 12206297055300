import Footer from "../common/Footer";
import Navbar from "../common/Navbar";

import React from "react";
type CommonLayoutProps = {
  children: React.ReactNode;
  isNavDark?: boolean;
};
const CommonLayout: React.FC<CommonLayoutProps> = ({
  children,
  isNavDark,
}: any) => {
  return (
    <div>
      <div className="container">
        {" "}
        <Navbar isNavDark={isNavDark} />
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default CommonLayout;
