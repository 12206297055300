//@ts-nocheck

const PillerCard = ({ image, title, hoverHeading, hoverText }) => {
  return (
    <div className="relative  text-center justify-center gap-4 w-100 group">
      {
        <img
          loading="lazy"
          className="w-[100%]  min-h-[23rem] h-[23rem] object-cover rounded-xl relative z-[-1]"
          src={image}
          alt=""
        ></img>
      }
      <h3 className="text:[20px] z-[-1] relative md:text-[14px] font-bold pt-3">
        {title}
      </h3>
      <div className="z-[-1] bottom-[12%] absolute opacity-0 group-hover:opacity-100 w-fit bg-[#0000006f] h-[55%] flex flex-col justify-center p-3 mx-2 rounded">
        <h2 className="text-[20px] md:text-[13px]  font-bold text-white">
          {hoverHeading}
        </h2>
        <p className="text-[16px] md:text-[12px]  text-white">{hoverText}</p>
      </div>
    </div>
  );
};

export default PillerCard;
