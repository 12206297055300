import donut from "../assets/images/donut.png";

import whitedot from "../assets/images/whitedot.svg";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface MiniChartProps {
  indexScore: number;
  width: number;
  smallGauge: boolean;
}
const MiniChartPdf = ({ indexScore, width, smallGauge }: MiniChartProps) => {
  const indicatorPosition = indexScore / 100;
  const rotationValue = `rotate(calc(${indicatorPosition} * 140deg))`;
  const translationValue = `translateY(40%)`;
  const percentageValue = width + "px";
  return (
    <div
      id="chart"
      className="flex justify-center items-center float-left   relative"
    >
      <img
        style={{
          transform: "",
          width: `${width}px`,
        }}
        src={donut}
        className={`${width}px`}
        // className="w-full"
      />

      {/* {smallGauge ? (
        <div className="absolute  right-[32px] top-[5px] text-xs dark:text-black">
          H
        </div>
      ) : (
        <div className="absolute font-bold right-[32px] top-[10px] text-xs ">
          High
        </div>
      )} */}
      <div
        className=""
        style={{
          position: "absolute",
          zIndex: 30,
          margin: "auto",
          width: `${percentageValue}`,
          aspectRatio: "1/1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: `${rotationValue} ${translationValue}`,
        }}
      >
        <img src={whitedot} style={{ width: "15%", margin: "auto" }} />
      </div>
      {/* {smallGauge ? (
        <div className="absolute left-[88px] text-xs bottom-[50px] dark:text-black ">
          L
        </div>
      ) : (
        <div className="absolute left-[121px] font-bold text-xs bottom-[75px]">
          Low
        </div>
      )} */}

      <div className="absolute z-60 flex flex-col justify-center items-center h-full mx-auto">
        <p
          id="ind_t_2"
          className={`text-sm font-semibold ${
            smallGauge ? "text-black" : "text-black dark:text-white "
          } `}
        >
          {indexScore ? indexScore : "--"}
        </p>
      </div>
    </div>
  );
};

export default MiniChartPdf;
