import Turnstile from "react-turnstile";

// ...

export default function FormCaptcha({}: any) {
  // const turnstile = useTurnstile();
  //   const navigate = useNavigate();
  const captchaKey = import.meta.env.VITE_CAPTCHA_KEY;
  return (
    <Turnstile
      style={{
        justifyContent: "center",
        backgroundColor: "transparent",
        width: "200px",
      }}
      sitekey={captchaKey}
      onError={() => {}}
      onVerify={() => {}}
    />
  );
}
