import { Globe } from "lucide-react";

const PricingData = [
  { region: "USA", price: "$5.00", flag: "🇺🇸" },
  { region: "Canada", price: "$5.00", flag: "🇨🇦" },
  { region: "EU", price: "€5.00", flag: "🇪🇺" },
  { region: "UK", price: "£5.00", flag: "🇬🇧" },
  { region: "Switzerland", price: "5.00 CHF", flag: "🇨🇭" },
  { region: "Japan", price: "¥500.00", flag: "🇯🇵" },
  { region: "Other*", price: "US$5.00", flag: "🌍" },
];

const GlobalPricing = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
        ADAPTA CS Global Prices
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {PricingData.map((item, index) => (
          <div
            key={index}
            className="bg-gray-50 p-4 rounded-md shadow transition-all duration-300 hover:shadow-md"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-2xl">{item.flag}</span>
              <span className="font-semibold text-gray-700">{item.region}</span>
            </div>
            <div className="text-xl font-bold text-blue-600">{item.price}</div>
          </div>
        ))}
      </div>
      <div className="mt-8 text-sm text-gray-600">
        <p>
          * We are developing local payment capabilities in the next few months.
        </p>
        <p className="mt-2">
          We use Stripe adaptive capabilities using their conversion rates.
        </p>
      </div>
      <div className="mt-6 flex justify-center">
        <Globe className="text-blue-500" size={24} />
        <span className="ml-2 text-gray-700">Global Pricing</span>
      </div>
    </div>
  );
};

export default GlobalPricing;
