const Survey = () => {
  return (
    <div>
      <h1 className="text-center text-3xl mt-5 font-semibold">
        Help us make Adapta climate score better.
      </h1>
      <div className="mt-6 px-4 sm:px-6 lg:px-8 max-w-[85rem] mx-auto">
        <iframe
          src="https://us8.list-manage.com/survey?u=d7151edee1db7a367c5f545c1&id=d257a57f7f&attribution=false"
          width="100%"
          height="600px"
          frameBorder="0"
          title="Mailchimp Survey"
        ></iframe>
      </div>
    </div>
  );
};

export default Survey;
