import { useCallback } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
const stripeKey = import.meta.env.VITE_STRIPE_KEY;
const stripeRedirect = import.meta.env.VITE_STRIPE_REDIRECT;
const stripePromise = loadStripe(stripeKey);
const base_url = import.meta.env.VITE_API_URL;
const Checkout = () => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const fullname = searchParams.get("fullname");
  const userId = searchParams.get("userId");
  const priceId = searchParams.get("priceId");

  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    const locale = localStorage.getItem("lang");
    return fetch(`${base_url}/v1/subscription/user/single/checkout-session?locale=${locale}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        price_id: priceId,
        base_url: stripeRedirect,

        user_id: userId,
        full_name: fullname,
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
