import { useState, useEffect } from "react";
import Confetti from "react-confetti";
import { useSearchParams } from "react-router-dom";
import baseInstance from "../api";
import { useTranslation } from "react-i18next";

export default function Congrats() {
  const [windowDimension, setWindowDimension] = useState({
    width: 0,
    height: 0,
  });
  const [showConfetti, setShowConfetti] = useState(true);
  const { t } = useTranslation("home");
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user_id");
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    // Function to call the API
    const fetchData = async () => {
      try {
        const response = await baseInstance(
          `/v1/subscription/user/single/checkout-success?user_id=${userId}&checkout_session_id=${sessionId}`
        );
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (userId && sessionId) {
      fetchData();
    }
  }, [userId, sessionId]);

  useEffect(() => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 via-green-100 to-purple-100 text-gray-800 p-4 font-sans">
      {showConfetti && (
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={false}
          numberOfPieces={200}
        />
      )}
      <div className="text-center max-w-2xl">
        <h1 className="text-7xl font-extrabold mb-8 text-green-600 tracking-tight">
          {t("Success!")}
        </h1>
        <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-3xl p-10 shadow-2xl">
          <p className="text-2xl mb-8 leading-relaxed text-gray-700 font-light">
            {t(
              "We have sent your credentials with a link to our application to your email."
            )}
          </p>
          <p className="text-lg text-gray-600">
            {t("In case of any issues signing in, please contact us at")}{" "}
            <a
              href="mailto:info@adapta.earth"
              className="text-green-600 hover:underline font-medium transition duration-300 ease-in-out"
            >
              info@adapta.earth
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
