import axiosClient from "axios";
const apiUrl = import.meta.env.VITE_API_URL;
const baseInstance = axiosClient.create({

    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-adpata-application": "user",
      "x-client-identifier": "web",
    },
  });
  

  export default baseInstance