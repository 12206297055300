import { useNavigate } from "react-router-dom";
import check from "../assets/icons/check.svg";

import "react-international-phone/style.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import styles from "../css/phoneInput.module.css";

const Pricing = ({
  page,
  setOpenMapPopup,
}: {
  page: boolean;
  setOpenMapPopup: () => {};
}) => {
  const { t } = useTranslation("home");
  const [pricePopup, setPricePopup] = useState(false);
  const navigate = useNavigate();
  const cardData = [
    {
      icon: check,
      title: t("Access to search history"),
    },
    {
      icon: check,
      title: t("Climate and agriculture news"),
    },
    {
      icon: check,
      title: t("AgriBot, ADAPTA’s AI Assistant"),
    },
    {
      icon: check,
      title: t(
        "ADAPTA’s annual market report with insights on global usage trends"
      ),
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth, you can omit it if you want instant scroll
    });
    //@ts-ignore
    setOpenMapPopup(true);
  };

  return (
    <div className="container mx-auto px-[30px] sm:px-6  ">
      <div className="mb-8">
        {!page ? (
          <h3 className="font-bold text-[24px] md:text-[50px] text-center">
            {t("Pricing and Subscription Model")}
          </h3>
        ) : (
          <></>
        )}
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 pb-12 gap-6">
        <div className="bg-gradient-to-br from-[#338FD9] to-[#CFC1FF] rounded-xl p-6 sm:p-8 flex flex-col justify-between">
          <div>
            <h4 className="text-2xl sm:text-3xl font-black text-white">
              {t("INDIVIDUAL REPORT")}
            </h4>
            <p className="text-sm sm:text-base font-normal text-white mt-3">
              {t("Get climate reports for any location worldwide .")}
            </p>
            <p className="text-sm sm:text-base font-normal text-white mt-3">
              {t("Local payment capabilities coming soon .")}
            </p>
            <p className="text-sm sm:text-base font-normal text-white mt-3">
              {t(
                "We use Stripe adaptive capabilities using their conversion rates."
              )}
            </p>
            <p className="text-sm sm:text-base font-normal text-white mt-2">
              {" "}
              {/* <span
                className="underline cursor-pointer"
                onClick={() => navigate("/prices-breakdown")}
              >
                Click Here
              </span> */}
            </p>
          </div>
          <button
            onClick={scrollToTop}
            className="w-full font-bold bg-white text-black  rounded-full text-sm px-4 py-3 sm:px-6 sm:py-4 mt-6 hover:bg-blue-50 transition-colors duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            {t("Buy Now")}
          </button>
        </div>
        <div className="bg-gradient-to-br from-[#338FD9] to-[#CFC1FF] rounded-xl p-6 sm:p-8 flex flex-col justify-between">
          <div>
            <h3 className="text-2xl sm:text-3xl font-black text-white">
              {t("SUBSCRIPTION API")}
            </h3>
            <div className="flex flex-col gap-2 mt-4">
              {cardData.map(({ icon, title }, index) => (
                <div key={index} className="flex items-center gap-2">
                  <img src={icon} alt="" className="w-5 h-5" />
                  <p className="text-sm sm:text-base font-normal text-white">
                    {title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/register")}
            className="w-full font-bold bg-white text-black rounded-full text-sm px-4 py-3 sm:px-6 sm:py-4 mt-6 hover:bg-blue-50 transition-colors duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            {t("Subscribe Now")}
          </button>
        </div>
        <div className="bg-gradient-to-br from-[#338FD9] to-[#CFC1FF] rounded-xl p-6 sm:p-8 flex flex-col justify-between">
          <div>
            <h3 className="text-2xl sm:text-3xl font-black text-white">
              {t("ANNUAL SUBSCRIPTION")}
            </h3>
            <p className="text-sm sm:text-base font-normal text-white mt-3">
              {t(
                "It offers all the benefits of the API package and can be customized to fit your needs . Please contact us for further information"
              )}
            </p>
          </div>
          <button
            onClick={() => navigate("/contact-us")}
            className="w-full font-bold bg-white text-black rounded-full text-sm px-4 py-3 sm:px-6 sm:py-4 mt-6 hover:bg-blue-50 transition-colors duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            {t("Contact Us")}
          </button>
        </div>
      </div>
      {pricePopup ? (
        <div className=" p-4 overflow-x-hidden fixed top-0 bg-[#00000045] left-[50%] translate-x-[-50%] z-50 justify-center items-center h-full   w-full">
          <div className="relative p-4 w-[90%] md:w-[60%] mx-auto">
            <div className="relative bg-gradient-to-r from-stone-50 to-amber-100 rounded-lg shadow dark:bg-gray-700">
              <div
                onClick={() => setPricePopup(false)}
                className="absolute right-3 top-3 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <div className="p-4 md:p-11 text-left gap-y-2 flex flex-col ">
                <h3 className="mb-1 text-xl font-bold text-gray-900 dark:text-white ">
                  Unlock the Power of ADAPTA Climate Score with Our Subscription
                  API Model
                </h3>

                <div className="flex  flex-col gap-y mb-1 text-gray-500 dark:text-gray-400">
                  <span className="text-base font-normal">
                    {" "}
                    Gain access to the full potential of ADAPTA Climate Score
                    and ADAPTA Agribot AI with our exclusive API subscription
                    model. Purchase a minimum of 100 credits, or more, at a 25%
                    discount, and enjoy seamless integration of climate, soil,
                    and water risk data – all stored in one centralized location
                    for easy access.
                  </span>
                  <span className="text-base font-normal">
                    {" "}
                    Credits are valid for one year, non-refundable, and can be
                    increased anytime to suit your growing needs. Empower your
                    decision-making with real-time insights and smart data
                    management from ADAPTA Climate Score.
                  </span>
                </div>
                {/* <div className="w-full bg-white rounded-full font-semibold text-gray-700 p-4">
                  For the B2C Buy Now it should show the price of US$5 or
                  equivalent 135 currencies.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Pricing;
