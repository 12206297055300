import { useLocation, useNavigate } from "react-router-dom";
import PricingCard from "../components/PricingCard";
import { usePrice } from "../context/Price";
import { useTranslation } from "react-i18next";
const Plans = () => {
  const { price }: any = usePrice();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email");
  const fullname = searchParams.get("fullname");
  const userId = searchParams.get("userId");

  console.log(email, fullname, userId, "ss");

  if (!price.products) {
    return <div>Loading...</div>; // Or any fallback UI
  }

  return (
    <div className="bg-gradient-to-br from-indigo-100 to-purple-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-indigo-900 text-center mb-4">
          {t("Choose Your Package")}
        </h2>
        <p className="text-xl text-indigo-700 text-center mb-12">
          {t("Select the perfect plan for your needs")}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {price?.products?.map((product: any, index: any) => (
            <div
              onClick={() => {
                navigate(
                  `/plancheckout?email=${encodeURIComponent(
                    //@ts-ignore
                    email
                  )}&fullname=${encodeURIComponent(
                    //@ts-ignore
                    fullname
                  )}&userId=${encodeURIComponent(
                    //@ts-ignore
                    userId
                  )}&priceId=${encodeURIComponent(product.price_id)}`
                );
              }}
            >
              <PricingCard
                email={email}
                fullname={fullname}
                userId={userId}
                key={product.price_id}
                product={product}
                isPopular={index === 1}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Plans;
