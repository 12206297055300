import { useState, useEffect } from "react";
import MiniChartPdf from "./MiniChartPdf";

interface Content {
  level: number;
}

function GaugePdf(props: Content) {
  console.log(props, "from gauge");
  const [, setCurrentValue] = useState(props.level);
  useEffect(() => {
    setCurrentValue(props.level);
  }, [props]);

  return (
    <>
      <div className="w-100 md:w-[25%] mb-6 md:md-0 ">
        <div className="">
          <div className="group flex flex-col">
            <div className="mx-auto">
              <MiniChartPdf
                // @ts-ignore
                indexScore={props.level?.toFixed(0)}
                width={80}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GaugePdf;
