import biodiversity from "../../assets/images/biodiversity.jpg";
import climate from "../../assets/images/climate.jpg";
import water from "../../assets/images/water.jpg";
import soil from "../../assets/images/soil.jpg";
import profitablity from "../../assets/images/Profitability.jpg";
import PillerCard from "../PillerCard";
import { useTranslation } from "react-i18next";

const Piller = () => {
  const { t } = useTranslation("home");
  const pillerData = [
    {
      image: biodiversity,
      title: t("biodiversity check"),
      hoverText: t("biodersity heading"),
      hoverHeading: t("biodiversity check"),
    },
    {
      image: climate,
      title: t("climate risk"),
      hoverText: t("climate heading"),
      hoverHeading: t("climate risk"),
    },
    {
      image: soil,
      title: t("soil risk"),
      hoverText: t("soil heading"),
      hoverHeading: t("soil risk"),
    },
    {
      image: water,
      title: t("water risk"),
      hoverText: t("water heading"),
      hoverHeading: t("water risk"),
    },
    {
      image: profitablity,
      title: t("profitablity"),
      hoverText: t("profitablity heading"),
      hoverHeading: t("profitablity"),
    },
  ];
  return (
    <div className="text-center  my-[25px] md:my-[100px]">
      <h2 className="text-[24px] md:text-[50px] font-black">
        {t("climate score pillers")}
      </h2>
      <p className="text-[16px] text-[#4E4E4E]">
        {t("we have developed a climate")}
      </p>
      <button
        type="button"
        className="text-white mt-6 bg-[#338FD9] border focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-[55px] py-[19px] text-center leading-4 mb-5"
      >
        {t("buy report")}
      </button>
      <div className="grid   xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mx-3">
        {pillerData.map(({ image, hoverHeading, hoverText, title }) => (
          <PillerCard
            title={title}
            image={image}
            hoverHeading={hoverHeading}
            hoverText={hoverText}
          />
        ))}
      </div>
    </div>
  );
};

export default Piller;
