import { useNavigate } from "react-router-dom";
import Turnstile from "react-turnstile";

// ...

export default function TurnstileWidget({ reportId }: any) {
  const captchaKey = import.meta.env.VITE_CAPTCHA_KEY;

  // const turnstile = useTurnstile();
  const navigate = useNavigate();

  return (
    <Turnstile
      sitekey={captchaKey}
      onError={() => {
        navigate({
          pathname: "/checkout",
          search: `?reportId=${reportId}`,
        });
      }}
      onVerify={() => {
        navigate({
          pathname: "/checkout",
          search: `?reportId=${reportId}`,
        });
      }}
    />
  );
}
