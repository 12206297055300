import { useSearchParams } from "react-router-dom";
import TurnstileWidget from "../components/Turnstile";

const VerifyHuman = () => {
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");

  return (
    <div className="flex flex-col gap-3 items-center justify-center h-screen">
      <h1>Verify that you are human</h1>
      <TurnstileWidget reportId={reportId} />
    </div>
  );
};

export default VerifyHuman;
