import "react-vertical-timeline-component/style.min.css";
import cropLivestock from "../assets/images/cropLivestock.png";
import coinStack from "../assets/images/coinStack.png";
import adaptation from "../assets/images/adaptation.png";
import location from "../assets/images/location.png";
import croplivestockselection from "../assets/images/croplivestockselection.png";
import { useTranslation } from "react-i18next";
const Timeline = () => {
  const { t } = useTranslation("home");
  return (
    <div className="md:container mx-auto w-full h-full px-[15px]">
      <h2 className="text-[24px] md:text-[50px] font-black text-center">
        {t("calculate climate score")}
      </h2>
      <div className="relative z-[-1] wrap overflow-hidden md:p-10 h-full mt-[30px]">
        <div
          className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border"
          style={{ left: "50%" }}
        ></div>
        <div className="mb-8 flex justify-center items-center w-full right-timeline">
          <div className="order-1 w-100"></div>
          <div className="bg-gradient-to-r from-sky-200 to-sky-500 w-5/12 p-2 md:p-7 order-1  rounded-lg mr-3">
            <img
              loading="lazy"
              className="w-screen"
              src={cropLivestock}
              alt=""
            ></img>
          </div>
          <div className="z-20 md:border-[5px]  md:border-white flex items-center order-1 bg-gradient-to-r from-sky-200 to-sky-500 mr-2 w-[16px] h-[16px]   md:w-[56px] md:h-[56px] rounded-full">
            <h1 className="mx-auto font-semibold text-[7px] md:text-lg  text-white">
              1
            </h1>
          </div>
          <div className="order-1 rounded-lg w-5/12 md:px-6 py-4 pl-2">
            <h3 className="mb-3 font-bold text-[14px] text-[#282828] md:text-xl">
              {t("Crop/Livestock Lever")}
            </h3>
            <ul className="text-[12px] md:text-sm leading-snug tracking-wide text-[#484848] text-opacity-100 list-disc list-outside ">
              <li className="flex gap-2 list-disc list-outside">
                {t("Crop/Livestock Lever")}
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8 flex justify-center md:flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 w-100"></div>
          <div className="bg-gradient-to-r from-sky-200 to-sky-500 w-5/12 p-2 md:p-7 order-1  rounded-lg ">
            <img
              className="w-screen"
              loading="lazy"
              src={location}
              alt=""
            ></img>
          </div>
          <div className="z-20 m-2 md:border-[5px]  md:border-white flex items-center order-1 bg-gradient-to-r from-sky-200 to-sky-500  mr-2 md:ml-5  w-[16px] h-[16px]   md:w-[56px] md:h-[56px] rounded-full">
            <h1 className="mx-auto font-semibold text-[7px] md:text-lg  text-white">
              2
            </h1>
          </div>
          <div className="order-1 rounded-lg  w-5/12 md:px-6 py-4 pl-2 ">
            <h3 className="mb-3 font-bold text-[14px] text-gray-800 md:text-xl">
              {t("Enter Location or GPS Coordinate to Find Farm")}
            </h3>
            <ul className="text-[12px] md:text-sm leading-snug tracking-wide text-[#484848] text-opacity-100 gap-y-2 flex flex-col">
              <li className="flex gap-2 list-disc">
                <p>{t("A. Google Earth window will appear.")}</p>
              </li>
              <li className="flex gap-2 list-disc">
                <p>{t("B. Enter a landmark and look for a farm.")}</p>
              </li>
              <li className="flex gap-2">
                <p>{t("C. You can also enter GPS Location.")}</p>
              </li>
              <li className="flex gap-2">
                <p>{t("D. Use the pointer to mark lands.")}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8 flex justify-center items-center w-full right-timeline">
          <div className="order-1 w-100"></div>
          <div className="bg-gradient-to-r from-sky-200 to-sky-500 w-5/12 p-2 md:p-7 order-1  rounded-lg mr-3">
            <img
              loading="lazy"
              className="w-screen"
              src={coinStack}
              alt=""
            ></img>
          </div>
          <div className="z-20 flex md:border-[5px]  md:border-white    items-center order-1 bg-gradient-to-r from-sky-200 to-sky-500 mr-2   w-[16px] h-[16px]   md:w-[56px] md:h-[56px] rounded-full">
            <h1 className="mx-auto font-semibold text-[7px] md:text-lg  text-white">
              3
            </h1>
          </div>
          <div className="order-1 rounded-lg w-5/12 md:px-6 py-4 pl-2">
            <h3 className="mb-3 font-bold text-[14px] text-gray-800 md:text-xl">
              {t("Enter Years to Forecast or Loan Period")}{" "}
            </h3>
            <ul className="text-[12px] md:text-sm leading-snug tracking-wide text-[#484848] text-opacity-100 gap-y-2 flex flex-col list-disc ">
              <li className="flex gap-2">
                <p>{t("A. You scroll and select the forecast period.")}</p>
              </li>
              <li className="flex gap-2">
                <p>
                  {t(
                    "B. Climate change is visible over more extended periods."
                  )}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8 flex justify-center md:flex-row-reverse items-center w-full left-timeline">
          <div className="order-1 w-100"></div>
          <div className="bg-gradient-to-r from-sky-200 to-sky-500 w-5/12 p-2 md:p-7 order-1  rounded-lg mr-3">
            <img
              loading="lazy"
              className="w-screen"
              src={croplivestockselection}
              alt=""
            ></img>
          </div>
          <div className="z-20 flex md:border-[5px]  md:border-white items-center order-1 bg-gradient-to-r from-sky-200 to-sky-500 mr-1 md:ml-3  w-[16px] h-[16px]   md:w-[56px] md:h-[56px] rounded-full">
            <h1 className="mx-auto font-semibold text-[7px] md:text-lg  text-white">
              4
            </h1>
          </div>
          <div className="order-1 rounded-lg  w-5/12 md:px-6 py-4 pl-2">
            <h3 className="mb-3 font-bold text-[14px] text-gray-800 md:text-xl">
              {t("Select Crop/Livestock")}{" "}
            </h3>
            <ul className="text-[12px] md:text-sm leading-snug tracking-wide text-[#484848]text-opacity-100 gap-y-2 flex flex-col list-disc ">
              <li className="flex gap-2">
                <p>
                  {t(
                    "A. If you moved the lever to crop, you get a list of crops select."
                  )}
                </p>
              </li>
              <li className="flex gap-2">
                <p>
                  {" "}
                  {t("B. If moved lever to livestock, select livestock type.")}
                </p>
              </li>
              <li className="flex gap-2">
                <p>
                  {t(
                    "C. Only for livestock, you will be asked questions to measure profitability risk."
                  )}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-8 flex justify-center items-center w-full right-timeline">
          <div className="order-1 w-100"></div>
          <div className="bg-gradient-to-r from-sky-200 to-sky-500 w-5/12 p-2 md:p-7 order-1  rounded-lg mr-3">
            <img
              loading="lazy"
              className="w-screen"
              src={adaptation}
              alt=""
            ></img>
          </div>
          <div className="z-20 flex md:border-[5px]  md:border-white    items-center order-1 bg-gradient-to-r from-sky-200 to-sky-500 mr-2   w-[16px] h-[16px]   md:w-[56px] md:h-[56px] rounded-full">
            <h1 className="mx-auto font-semibold text-[7px] md:text-lg  text-white">
              5
            </h1>
          </div>
          <div className="order-1 rounded-lg w-5/12 md:px-6 py-4 pl-2">
            <h3 className="mb-3 font-bold text-[14px] text-gray-800 md:text-xl">
              {t("Adaptation Suggestions")}{" "}
            </h3>
            <ul className="text-[12px] md:text-sm gap-y-3 leading-snug tracking-wide text-[#484848] text-opacity-100 gap-y-2 flex flex-col">
              <li className="flex gap-2">
                <p>
                  {t(
                    "ADAPTA CS provides you a set of suggestions to adapt to or Our goal is simple: enhance the resilience of the farm and the creditworthiness of a farmer."
                  )}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
