import { useNavigate } from "react-router-dom";
import Turnstile from "react-turnstile";

// ...

export default function TurnstileLivestock({ reportId }: any) {
  //   const turnstile = useTurnstile();
  const navigate = useNavigate();
  return (
    <Turnstile
      sitekey="0x4AAAAAAAjAvZXlNfij-9vS"
      onError={() => {
        navigate({
          pathname: "/checkout-livestock",
          search: `?reportId=${reportId}`,
        });
      }}
      onVerify={() => {
        navigate({
          pathname: "/checkout-livestock",
          search: `?reportId=${reportId}`,
        });
      }}
    />
  );
}
