// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation JSON files for different pages
import homeEN from './locales/en/home.json';
import aboutEN from './locales/en/aboutUs.json';
import contactEN from './locales/en/contactUs.json';
import helpEN from './locales/en/help.json';

import homeFR from './locales/fr/home.json';
import aboutFR from './locales/fr/aboutUs.json';
import contactFR from './locales/fr/contactUs.json';
import helpFR from './locales/fr/help.json';


import homeES from './locales/es/home.json';
import aboutES from './locales/es/aboutUs.json';
import contactES from './locales/es/contactUs.json';
import helpES from './locales/es/help.json';


import homePT from './locales/pt/home.json';
import aboutPT from './locales/pt/aboutUs.json';
import contactPT from './locales/pt/contactUs.json';
import helpPT from './locales/pt/help.json';


import homeDE from './locales/de/home.json';
import aboutDE from './locales/de/aboutUs.json';
import contactDE from './locales/de/contactUs.json';
import helpDE from './locales/de/help.json';

import homeHI from './locales/hi/home.json';
import aboutHI from './locales/hi/aboutUs.json';
import contactHI from './locales/hi/contactUs.json';
import helpHI from './locales/hi/help.json';

import homeZH from './locales/zh/home.json';
import aboutZH from './locales/zh/aboutUs.json';
import contactZH from './locales/zh/contactUs.json';
import helpZH from './locales/zh/help.json';

import homeID from './locales/id/home.json';
import aboutID from './locales/id/aboutUs.json';
import contactID from './locales/id/contactUs.json';
import helpID from './locales/id/help.json';

import homeJA from './locales/ja/home.json';
import aboutJA from './locales/ja/aboutUs.json';
import contactJA from './locales/ja/contactUs.json';
import helpJA from './locales/ja/help.json';


import homeTL from './locales/tl/home.json';
import aboutTL from './locales/tl/aboutUs.json';
import contactTL from './locales/tl/contactUs.json';
import helpTL from './locales/tl/help.json';

import homeRU from './locales/ru/home.json';
import aboutRU from './locales/ru/aboutUs.json';
import contactRU from './locales/ru/contactUs.json';
import helpRU from './locales/ru/help.json';

const resources = {
  en: {
    home: homeEN,
    about: aboutEN,
    contact: contactEN,
    help: helpEN,
  },
  fr: {
    home: homeFR,
    about: aboutFR,
    contact: contactFR,
    help: helpFR,
  },
  es: {
    home: homeES,
    about: aboutES,
    contact: contactES,
    help: helpES,
  },
  pt: {
    home: homePT,
    about: aboutPT,
    contact: contactPT,
    help: helpPT,
  },
  de: {
    home: homeDE,
    about: aboutDE,
    contact: contactDE,
    help: helpDE,
  },
  hi: {
    home: homeHI,
    about: aboutHI,
    contact: contactHI,
    help: helpHI,
  },
  zh: {
    home: homeZH,
    about: aboutZH,
    contact: contactZH,
    help: helpZH,
  },
  id: {
    home: homeID,
    about: aboutID,
    contact: contactID,
    help: helpID,
  },
  ja: {
    home: homeJA,
    about: aboutJA,
    contact: contactJA,
    help: helpJA,
  },
  tl: {
    home: homeTL,
    about: aboutTL,
    contact: contactTL,
    help: helpTL,
  },
  ru: {
    home: homeRU,
    about: aboutRU,
    contact: contactRU,
    help: helpRU,
  },
};

i18n
  .use(initReactI18next) // Connects i18n with react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Fallback to English if the current language is missing
    debug: true,

    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;