import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import baseInstance from "../api";
import { usePrice } from "../context/Price";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
// Define the validation schema
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  full_name: yup.string().required("Full name is required"),
  email_is_checked: yup.boolean(),
});

const RegisterForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setPrice }: any = usePrice();
  const { t } = useTranslation("home");
  console.log(setPrice, "setpriceee");
  const onSubmit = async (data: any) => {
    console.log(data);
    // Here you would typically send the data to your API
    // For example:

    try {
      const res = await baseInstance.post(
        "/v1/subscription/user/single/register",
        data
      );
      setPrice(res?.data);

      navigate(
        `/plans?email=${encodeURIComponent(
          data.email
        )}&fullname=${encodeURIComponent(
          data.full_name
        )}&userId=${encodeURIComponent(res.data.id)}`
      );
      console.log(res, "res");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: t("Oops"),
        text: t("Email address already in use ."),
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
          {t("Register your account")}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                {t("Email address")}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  required
                  {...register("email")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-150 ease-in-out"
                />
              </div>
              {errors.email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="full_name"
                className="block text-sm font-medium text-gray-700"
              >
                {t("Name")}
              </label>
              <div className="mt-1">
                <input
                  id="full_name"
                  type="text"
                  autoComplete="name"
                  required
                  {...register("full_name")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-150 ease-in-out"
                />
              </div>
              {errors.full_name && (
                <p className="mt-2 text-sm text-red-600" id="full-name-error">
                  {errors.full_name.message}
                </p>
              )}
            </div>

            <div className="flex items-center">
              <input
                id="email_is_checked"
                type="checkbox"
                {...register("email_is_checked")}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="email_is_checked"
                className="ml-2 block text-sm text-gray-900"
              >
                {t("Want to receive promotional content")}
              </label>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out transform hover:-translate-y-1 hover:scale-105"
              >
                {t("Register")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
