//@ts-nocheck
import CommonLayout from "../components/layout/CommonLayout";
import contactUsImg from "../assets/images/contactus.png";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
// import styles from "../css/phoneInput.module.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import baseInstance from "../api";
import { useTranslation } from "react-i18next";
const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  requester_phone_number: yup.string().required("required"),
  // .min(10, "too short")
  // .max(14, "too long"),
  // requester_company: yup.string()?.required("Company name is required"),
  // requester_company_website: yup
  //   .string()
  //   .url("Invalid URL")
  //   .required("Company website is required"),
  message: yup.string().required("Message is required"),
});

export const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation("contact");
  const onSubmit = async (data: any) => {
    console.log("running");
    // const subject = `Contact us from ${data.name} from ${data.companyName}`;
    data.request_type = "INFO";
    console.log(data, "contact");
    try {
      // setFormData((prevData) => ({
      //   ...prevData,
      //   subject: subject,
      //   email: data.email,
      //   message: data.message,
      //   name: data.name,
      //   phone: data.phone,
      //   companyName: data.companyName,
      //   companyWebsite: data.companyWebsite,
      // }));

      const response = await baseInstance({
        method: "POST",
        url: "/emails/landing-page/request",
        data,
      });
      const responseData = response.data;
      reset();
      toast.success(responseData.message);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <CommonLayout isNavDark={true}>
        <div className="container py-4">
          <h1 className="font-black py-4 md:py-10 text-[28px] sm:text-5xl md:text-6xl text-center  md:text-left lg:text-8xl">
            {t("CONTACT US FOR DEMONSTRATION")}
          </h1>
          <div className=" w-full md:py-12 pb-9">
            <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
              <img
                loading="lazy"
                className="h-auto w-full w-100 md:max-w-[331px] mx-auto lg:mx-0"
                src={contactUsImg}
                alt="Contact Us"
              />
              <div className="w-full">
                <div className="text-2xl text-center md:text-left sm:text-3xl md:text-4xl font-semibold leading-tight mb-6">
                  {t("We thought you'd")}{" "}
                  <span className="block">
                    {t("Never ask!")}{" "}
                    <span className="text-[#338FD9]">{t("Let's talk!")}</span>
                  </span>
                </div>

                <form className="space-y-4 " onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <input
                      {...register("requester_name")}
                      placeholder={t("Name")}
                      className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#338FD9] focus:ring-opacity-30"
                    />
                    <input
                      {...register("requester_email")}
                      placeholder={t("Email")}
                      className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#338FD9]  rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <Controller
                      control={control}
                      name="requester_phone_number"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <PhoneInput
                          className="w-full"
                          defaultCountry="ke"
                          inputClassName="w-full"
                          name="phone"
                          onChange={onChange}
                        />
                      )}
                    />
                    {/* <input
                      placeholder="Subject"
                      className="w-full sm:w-1/2 text-slate-600 bg-white border border-[#008D06] rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                    /> */}
                  </div>
                  <textarea
                    className="w-full text-slate-600 bg-white border border-[#338FD9]  rounded-lg px-3.5 py-2.5 outline-none focus:ring-2 focus:ring-[#008D06] focus:ring-opacity-30"
                    name="message"
                    {...register("message")}
                    id="message"
                    placeholder={t("Your request")}
                    rows="4"
                    required
                  ></textarea>
                  <button
                    omClick={handleSubmit(onSubmit)}
                    type="submit"
                    className="inline-block w-full md:w-fit px-6 py-2 rounded-[26px] bg-[#338FD9] text-white text-sm sm:text-base hover:bg-[#006D04] transition-colors duration-300"
                  >
                    {t("Submit")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};
