
import baseInstance from "./api";
export const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  async function isInProtectedArea(coordinates:any) {
    const postCord = { latitude: coordinates[1], longitude: coordinates[0] };
    console.log(postCord, "post");
  
    try {
      const response = await baseInstance.post(
        `/v1/public/is-in-protected-area`,
        postCord
      );
      console.log(response.data.geojson, "api ");
  
      return [response?.data?.status, response?.data?.geojson];
    } catch (error) {
      console.error("Error fetching or processing GeoJSON data:", error);
      return false;
    }
  }
  
  export { isInProtectedArea };